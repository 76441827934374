import { TextInfo } from '@/components/shared';
import { EditContactButton } from '../EditContactButton';
import { isCorporativeEmail } from '../helper/is-corporative-email';
import { events, useEventTracker } from '@/api';
import { EditResellerEmail } from '../../../edit-reseller-email';
import { useGetResellerLastUpdateStatus } from '@/api';
import { getEmailUpdateStatusParameters } from './get-update-status-info';
import { Alert } from '@/components/shared/alert';
import { AttendanceInfoData } from '../../../shared/types';
import { ContactFooterText } from '../components/ContactFooterText';

type ResellerContactEmailProps = { phone: string; email: string; attendanceInfo: AttendanceInfoData };

export const ResellerContactEmail = ({ email, phone, attendanceInfo }: ResellerContactEmailProps) => {
  const { clickedResellerInfoButtons } = useEventTracker();
  const { data: lastUpdateStatus, isLoading: lastUpdateStatusLoading } = useGetResellerLastUpdateStatus(
    attendanceInfo.geraId,
    { field: 'email' },
  );

  const handleEditResellerEmail = () => {
    clickedResellerInfoButtons({
      section: 'alterar-dados-email',
    });

    events.emit('OPEN_DRAWER_INFO', {
      size: 'medium',
      title: 'Alteração de e-mail',
      content: <EditResellerEmail attendanceInfo={attendanceInfo} resellerEmail={email} />,
    });
  };

  const isCorporative = isCorporativeEmail(email);

  const statusParameters = getEmailUpdateStatusParameters(lastUpdateStatus, phone?.slice(-4));

  const shouldDisableEditButton = lastUpdateStatusLoading || statusParameters?.disabled || isCorporative;
  const disabledButtonTooltip =
    statusParameters?.buttonTooltip ||
    'Só é possível solicitar a alteração de um e-mail corporativo via Central de Atendimento ao Revendedor (CAR)';

  const EditEmailButton = (
    <EditContactButton
      label={`Editar e-mail`}
      onEdit={handleEditResellerEmail}
      disabled={shouldDisableEditButton}
      disabledTooltipText={disabledButtonTooltip}
    />
  );

  return (
    <>
      <TextInfo
        canCopyToClipboard
        isFullWidth
        title="E-Mail"
        text={email}
        actions={[EditEmailButton]}
        footerElement={<ContactFooterText text={statusParameters?.infoText} type={statusParameters?.infoType} />}
      />
      {statusParameters?.alertText && (
        <Alert
          title={statusParameters.alertTitle}
          description={statusParameters.alertText}
          status={statusParameters.alertType}
        />
      )}
    </>
  );
};
