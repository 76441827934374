import { Flex } from '@grupoboticario/flora-react';
import { AttendanceInfoData } from '@/components/reseller-details/';
import { EditResellerEmailForm } from './components';

interface EditResellerEmailProps {
  attendanceInfo: AttendanceInfoData;
  resellerEmail: string;
}

const EditResellerEmail = ({ attendanceInfo, resellerEmail }: EditResellerEmailProps) => (
  <Flex data-testid="edit-reseller-email-wrapper" direction="column" gap="$6" css={{ height: '100%' }}>
    <EditResellerEmailForm resellerGeraId={attendanceInfo.geraId} resellerEmail={resellerEmail} />
  </Flex>
);

export { EditResellerEmail };
