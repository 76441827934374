import { Button, Flex, Text } from '@grupoboticario/flora-react';
import { PlayCircleFillIcon } from '@grupoboticario/flora-react-icons';
import { useParams } from 'react-router-dom';
import { useSWRConfig } from 'swr';
import { useGetService, useHandleCloseService, startCustomerService, events, useEventTracker } from '@/api';
import { ServiceRouteParamsType, ServiceStatus, Service, myAttendancesKey } from '@/types';
import { Loading } from '../shared';

export const ServiceControl = () => {
  const { mutate } = useSWRConfig();
  const { serviceId } = useParams<ServiceRouteParamsType>();
  const { service, isLoading } = useGetService(serviceId);
  const { startFinishService } = useEventTracker();

  const { handleCloseService } = useHandleCloseService({
    serviceId,
    serviceStatus: service?.status,
    geraId: service?.reseller.geraId,
    registryName: service?.reseller.name,
  });

  const isServiceStarted = service?.status === ServiceStatus.IN_ATTENDANCE;

  const handleUpdateServiceList = async (startedService: Service) => {
    await mutate(
      myAttendancesKey,
      (cachedServices: Service[] | undefined) => {
        const services = cachedServices ?? [];
        const serviceIndex = services.findIndex(entry => entry._id === serviceId);

        const serviceToUpdate = {
          ...services[serviceIndex],
          status: startedService.status,
        };
        services[serviceIndex] = serviceToUpdate;

        return [...services];
      },
      false,
    );
  };

  const handleStartService = async () => {
    if (serviceId) {
      try {
        const resp = await startCustomerService(serviceId);
        handleUpdateServiceList(resp);
        mutate(['service', serviceId], resp);
        startFinishService({
          interactionDetail: ServiceStatus.OPEN,
        });
      } catch (error) {
        events.error({
          duration: 60000,
          description: 'Não foi possível iniciar o atendimento.',
        });
      }
    }
  };

  return (
    <Flex
      key={serviceId}
      css={{
        padding: '$4 0 0 0',
      }}
      direction="column"
    >
      {isLoading ? (
        <Flex css={{ padding: '$6 0' }} justify="center">
          <Loading />
        </Flex>
      ) : (
        <Flex
          css={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Text as="h2" size="subTitleDeskBold">
            Atendimento
          </Text>
          <Button
            styleSemantic={!isServiceStarted ? 'success' : 'neutral'}
            icon={!isServiceStarted ? <PlayCircleFillIcon /> : null}
            has="iconLeft"
            css={{ marginLeft: 'auto' }}
            onClick={!isServiceStarted ? handleStartService : handleCloseService}
          >
            {!isServiceStarted ? 'Iniciar atendimento' : 'Finalizar atendimento'}
          </Button>
        </Flex>
      )}
    </Flex>
  );
};
