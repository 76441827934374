import { useEventTracker } from '@/api';
import { useGTMTrackerStore } from '@/store';
import { ResellerTab } from '@/types';
import { Tab, TabList } from '@grupoboticario/flora-react';
import { useShallow } from 'zustand/react/shallow';

interface ResellerTabsProps {
  tabList: ResellerTab[];
}

const mapTabName = new Map<string, string>([
  ['Dados da IR', 'dados-da-ir'],
  ['Dados da CS', 'visao-geral'],
  ['Visão geral', 'visao-geral'],
  ['Documentação', 'documentacao'],
  ['Inclusão de produto', 'inclusao-produto'],
  ['Retirada e entrega', 'retirada-entrega'],
  ['Bloqueio e limite', 'bloqueio-limite'],
  ['Pagamento', 'pagamento'],
]);

const ResellerTabList = ({ tabList }: ResellerTabsProps): JSX.Element => {
  const { clickedTabs } = useEventTracker();
  const { setSessionTabName } = useGTMTrackerStore(useShallow(state => state));

  return (
    <TabList css={{ width: '100%' }} aria-label="Navbar de detalhes da IR">
      {tabList.length &&
        tabList.map(tab => (
          <Tab
            {...tab}
            key={tab.value}
            data-testid={`tab-${tab.value}`}
            onClick={() => {
              setSessionTabName(mapTabName.get(tab.title), tab.tabType || 'tab');
              clickedTabs(mapTabName.get(tab.title), tab.tabType || 'tab');
            }}
          >
            {tab.title}
          </Tab>
        ))}
    </TabList>
  );
};

export { ResellerTabList };
