import * as yup from 'yup';
import { HTTPError } from 'ky';
import { searchReseller } from '@/api';
import { SearchType } from '@/types';
import { EditEmailFormData } from '../../../types';
import { isCorporativeEmail } from '@/components/reseller-details/components/reseller-data/reseller-contact/helper/is-corporative-email';

const INPUT_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const EMAIL_ERROR_MESSAGE = 'E-mail inválido. Tente novamente.';
const EMAIL_NOT_AVAILABLE_MESSAGE = 'O e-mail informado já está sendo utilizado.';
const EMAIL_SAME_AS_PREVIOUS = `O e-mail informado é o mesmo do cadastro.`;
const CONFIRM_EMAIL_NOT_EQUAL = 'Os e-mails não estão iguais. Digite novamente.';
const SAME_EMAIL_ERROR = 'O e-mail informado é o mesmo do cadastro.';
const CORPORATIVE_EMAIL_ERROR =
  'Só é possível solicitar a alteração para um e-mail corporativo via Central de Atendimento ao Revendedor (CAR).';

// ignorando TS por problema de validação do schema https://github.com/jquense/yup/issues/959
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editResellerEmailFormSchema: yup.ObjectSchema<EditEmailFormData> = yup.object({
  email: yup
    .string()
    .email(EMAIL_ERROR_MESSAGE)
    .required(INPUT_ERROR_MESSAGE)
    .test({
      message: SAME_EMAIL_ERROR,
      test() {
        return true;
      },
    })
    .test({
      message: CORPORATIVE_EMAIL_ERROR,
      test(value: string | undefined) {
        return !isCorporativeEmail(value);
      },
    })
    .test({
      message: EMAIL_SAME_AS_PREVIOUS,
      test(value: string | undefined) {
        if (!value) {
          return false;
        }

        return value !== this.options.context?.existingEmail;
      },
    })
    .test({
      message: EMAIL_NOT_AVAILABLE_MESSAGE,
      async test(value: string | undefined) {
        if (!value) {
          return false;
        }
        const { resellerGeraId } = this.options.context;
        try {
          const foundResellerByEmail = (await searchReseller(value, SearchType.EMAIL)).find(
            reseller => reseller.email === value,
          );

          if (foundResellerByEmail?.geraId === resellerGeraId) {
            return true;
          }
        } catch (error) {
          if (error && (error as HTTPError).response?.status === 404) {
            return true;
          }
        }
        return false;
      },
    }),

  confirmEmail: yup
    .string()
    .email(EMAIL_ERROR_MESSAGE)
    .required(INPUT_ERROR_MESSAGE)
    .test({
      message: CONFIRM_EMAIL_NOT_EQUAL,
      test(value: string | undefined) {
        return value == this.resolve(yup.ref('email'));
      },
    }),
});
