import React, { useMemo } from 'react';
import { useVDIFeatureFlag } from '@/contexts';
import { FeatureFlag } from '@/types';

interface FeatureFlagInfo {
  isFeatureEnabled: boolean;
  loading: boolean;
}

export interface FeatureFlagContextValue {
  viewTabulateHistory: FeatureFlagInfo;
  viewMadmaxResellerUpdate: FeatureFlagInfo;
  viewNewServiceUnification: FeatureFlagInfo;
}

const generateFlagDefaultValue = () => ({
  isFeatureEnabled: false,
  loading: false,
});
const generateFeatureFlagContextDefaultValue = () => ({
  viewTabulateHistory: generateFlagDefaultValue(),
  viewMadmaxResellerUpdate: generateFlagDefaultValue(),
  viewNewServiceUnification: generateFlagDefaultValue(),
});

const FeatureFlagContext = React.createContext<FeatureFlagContextValue>(generateFeatureFlagContextDefaultValue());

// eslint-disable-next-line @typescript-eslint/ban-types
const FeatureFlagProvider = ({ children }: React.PropsWithChildren<{}>) => {
  const viewTabulateHistory = useVDIFeatureFlag(FeatureFlag.TABULATE_HISTORY);
  const viewMadmaxResellerUpdate = useVDIFeatureFlag(FeatureFlag.MADMAX_RESELLER_UPDATE);
  const viewNewServiceUnification = useVDIFeatureFlag(FeatureFlag.SERVICE_UNIFICATION);

  const contextValue = useMemo(
    (): FeatureFlagContextValue => ({
      viewTabulateHistory,
      viewMadmaxResellerUpdate,
      viewNewServiceUnification,
    }),
    [viewTabulateHistory, viewMadmaxResellerUpdate, viewNewServiceUnification],
  );

  return <FeatureFlagContext.Provider value={contextValue}>{children}</FeatureFlagContext.Provider>;
};

const useFeatureFlagContext = (): FeatureFlagContextValue => {
  const context = React.useContext(FeatureFlagContext);

  if (context === undefined) {
    throw new Error('useFeatureFlagContext deve ser utilizado dentro de um FeatureFlagContext.Provider');
  }

  return context;
};

export { FeatureFlagContext, FeatureFlagProvider, useFeatureFlagContext, generateFeatureFlagContextDefaultValue };
