import { useEventTracker } from '@/api';
import { FormInput } from '@/components/form';
import { Divider, FormWrapper } from '@/components/shared';
import { formName } from '@/types';
import { Box, Flex } from '@grupoboticario/flora-react';
import { FormProvider, useForm } from 'react-hook-form';
import { EditEmailFormData } from '../../types';
import { editResellerEmailFormSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditEmailErrorMessage } from './EditEmailErrorMessage';
import { updateResellerEmail } from '@/api/reseller/service';
import { EditEmailSuccessMessage } from './EditEmailSuccessMessage';
import { EditResellerEmailFooter } from './EditResellerEmailFooter';

interface EditResellerEmailFormProps {
  resellerEmail: string;
  resellerGeraId: string;
}

const EditResellerEmailForm = ({ resellerGeraId, resellerEmail }: EditResellerEmailFormProps) => {
  const { resellerInfoEditDataSubmitted, resellerInfoEditDataErrorSubmitted } = useEventTracker();

  const methods = useForm({
    resolver: yupResolver(editResellerEmailFormSchema),
    mode: 'onBlur',
    context: {
      existingEmail: resellerEmail,
      resellerGeraId,
    },
  });
  const {
    formState: { isSubmitSuccessful, isSubmitted },
  } = methods;

  const onSubmit = async (data: EditEmailFormData) => {
    if (resellerGeraId === undefined) {
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerContact,
        errorMessage: 'Gera ID não pode ser nulo',
      });
      throw new Error('Gera ID não pode ser nulo');
    }

    try {
      await updateResellerEmail(resellerGeraId, { email: data.email });
      resellerInfoEditDataSubmitted({ editFormName: formName.resellerEmail });

      return 0;
    } catch (err) {
      const errorMessage = (await err.response.json()).response?.error;
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerEmail,
        errorMessage: err.message,
      });
      if (errorMessage === 'UPDATE_IN_PROGRESS') {
        methods.setError('root', { type: 'UPDATE_IN_PROGRESS' });
      } else {
        methods.setError('root', { type: 'REQUEST_ERROR' });
      }
    }
  };

  return (
    <Flex direction="column" gapY="$2" data-testid="edit-reseller-address-form" css={{ marginTop: '$4', flex: 1 }}>
      <FormProvider {...methods}>
        <Box as="form" id="reseller-form" onSubmit={methods.handleSubmit(onSubmit)} css={{ flex: 1 }}>
          {isSubmitted ? (
            isSubmitSuccessful ? (
              <EditEmailSuccessMessage />
            ) : (
              <EditEmailErrorMessage />
            )
          ) : (
            <FormWrapper css={{ background: '$light-dark-1', padding: 0, paddingTop: '$2', flex: 1 }}>
              <Box css={{ gridColumn: 'span 12' }}>
                <FormInput
                  registerName="email"
                  data-testid="input-email"
                  id="email"
                  labelText="Novo e-mail:"
                  placeholder="email@email.com"
                  validText="E-mail preenchido corretamente."
                />
              </Box>
              <Box css={{ gridColumn: 'span 12' }}>
                <FormInput
                  registerName="confirmEmail"
                  data-testid="input-confirm-email"
                  id="confirm-email"
                  labelText="Confirmar e-mail:"
                  placeholder="email@email.com"
                  validText="E-mail preenchido corretamente."
                />
              </Box>
            </FormWrapper>
          )}
        </Box>
        <Divider />
        <EditResellerEmailFooter />
      </FormProvider>
    </Flex>
  );
};

export { EditResellerEmailForm };
