import { TabType } from './../../store/store';
import { ServiceStatus } from '@/types';
import { TabProps } from '@grupoboticario/flora-react';

export enum SearchType {
  CPF = 'cpf',
  EMAIL = 'email',
  GERA_ID = 'geraId',
  UNKNOWN = 'UNKNOWN',
  PHONE = 'phone',
}

export enum ResellerDocumentStatusEnum {
  PENDING = 'PENDENTE',
  CHECKED = 'VERIFICADO',
  NOT_CHECKED = 'NAO_VERIFICADO',
  NOT_DEFINED = 'NAO_DEFINIDO',
}

export enum ResellerOrigin {
  SALES_FORCE = 1,
  SPONTANEOUS = 2,
  VIRTUAL_STORE = 3,
  VIRTUAL_STORE_ORDER = 4,
  LEGACY_IMPORTED = 5,
  LOAD_IMPORTED = 6,
  SITE = 7,
  SEND_SCREEN = 8,
  RESELLER_REGISTER = 9,
  REGISTER_REQUEST = 10,
  SUPERVISOR_APP = 11,
}

export enum ResellerDocumentEnum {
  RG = 'RG',
  CPF = 'CPF',
  ADDRESS_PROOF = 'COMPROVANTE_RESIDENCIA',
}

export enum RiskAnalysis {
  NOT_PROCESSED = 'notProcessed',
  LOW_RISK = 'lowRisk',
  MEDIUM_RISK = 'mediumRisk',
  HIGH_RISK = 'highRisk',
  VERY_HIGH_RISK = 'veryHighRisk',
}

export enum ResellerEmailType {
  PERSONAL = 1,
  ALTERNATIVE = 2,
  BUSINESS = 3,
  EXTERNAL_SYSTEM = 4,
}

export enum ResellerPhoneType {
  HOME = 1,
  MOBILE = 2,
  MESSAGE_PHONE = 3,
  BUSINESS = 4,
  BUSINESS_ALTERNATIVE = 5,
  BUSINESS_FAX = 6,
  PROFESSIONAL_REFERENCE = 7,
  HOME_EXTERNAL_SYSTEM = 8,
  MOBILE_EXTERNAL_SYSTEM = 9,
}

export enum ResellerAddressType {
  HOME = 1,
  DELIVERY = 3,
  DELIVERY_ALTERNATIVE_1 = 4,
  // eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values
  DELIVERY_ALTERNATIVE_2 = 4,
  DELIVERY_OMNI = 8,
}

export interface Address {
  city: string;
  complement?: string;
  landmark: string;
  number: string;
  state: string;
  street: string;
  type: ResellerAddressType;
  zipCode: string;
  reference?: string;
}

export interface ResellerCredit {
  availableCreditLimit: number;
  totalCreditLimit: number;
  creditLimitInvoiced: number;
  creditTolerance: number;
  creditLimitDue: number;
}

export interface ResellerDocuments {
  documentos: ResellerDocumentStatus[];
}

export interface ResellerDocumentStatus {
  tipoDocumento: ResellerDocumentEnum;
  status: ResellerDocumentStatusEnum;
}

interface CommercialStructure {
  code: string;
  name: string;
  parentStructure: {
    code: string;
    name: string;
  };
}

export interface ResellerEmail {
  type: ResellerEmailType;
  email: string;
}

export interface ResellerPhone {
  type: ResellerPhoneType;
  phone: string;
  smsAccepted: boolean;
}

interface Reseller {
  cpf: string;
  geraId: string;
  hasFirstOrder: boolean;
}

export interface ResellerContact {
  phones: ResellerPhone[];
  emails?: ResellerEmail[];
}

export interface ResellerRegistry extends Reseller, ResellerContact {
  addresses: Address[];
  birthday: string;
  commercialStructure?: CommercialStructure;
  registrationDate: string;
  name: string;
  rg: string;
  socialName: string;
  registrationOrigin: ResellerOrigin;
}

export interface ResellerVO extends Reseller {
  attendanceId?: string;
  name: string;
  email: string;
  phone: string;
  serviceStatus: ServiceStatus;
}

export interface UpdateResellerData {
  isSubmitting: boolean;
  hasError?: boolean;
}

export interface ResellerTab extends Omit<TabProps, 'children'> {
  title: string;
  component: React.ReactNode;
  value: string;
  tabType?: TabType;
}

export enum UpdateStatus {
  UPDATE_COMPLETED = 'updateCompleted',
  UPDATE_RECENTLY_COMPLETED = 'updateRecentlyCompleted',
  UPDATE_IN_PROGRESS = 'updateInProgress',
  TWO_FA_WAITING_FOR_USER = 'updateInProgressWaitingTwoFa',
  BIO_FA_WAITING_FOR_USER = 'updateInProgressWaitingBio',
  BIO_FRAUD = 'bioFraud',
  TWO_FA_FRAUD = 'twoFaFraud',
  BIO_LIFECYCLE_EXPIRED = 'bioLinkExpired',
  BIO_NOT_SENT = 'bioNotSent',
  SERVER_ERROR = 'serverError',
  UPDATE_FAILED = 'updateFailed',
}

export interface ResellerLastUpdateStatus {
  resellerId: string;
  statusDate: string;
  fieldsChanged: string;
  status: UpdateStatus;
}

export const formName = {
  documentStatus: 'validar-documentos',
  resellerName: 'alterar-nome',
  resellerAddress: 'alterar-endereco',
  resellerContact: 'alterar-contato',
  resellerEmail: 'alterar-email',
  resellerPhone: 'alterar-celular',
};
