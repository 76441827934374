import { fetcher } from '@/api';
import { Service, ServiceStatus } from '@/types';

export async function addAttendanceToList(
  geraId: string,
  status: ServiceStatus = ServiceStatus.OPEN,
): Promise<Service> {
  const response = await fetcher.post('services', {
    json: { resellerId: geraId, status },
  });

  return response.json();
}
