import { useEventTracker } from '@/api';
import { FormInput } from '@/components/form';
import { Divider, FormWrapper } from '@/components/shared';
import { formName } from '@/types';
import { Box, Flex } from '@grupoboticario/flora-react';
import { FormProvider, useForm } from 'react-hook-form';
import { EditPhoneFormData } from '../../types';
import { editResellerPhoneFormSchema } from './validators';
import { yupResolver } from '@hookform/resolvers/yup';
import { EditPhoneErrorMessage } from './EditPhoneErrorMessage';
import { EditPhoneSuccessMessage } from './EditPhoneSuccessMessage';
import { EditResellerPhoneFooter } from './EditResellerPhoneFooter';
import { updateResellerPhone } from '@/api/reseller/service/updateResellerPhone';
import { cleanupSpecialCharacters } from '@/utils';

interface EditResellerPhoneFormProps {
  resellerPhone: string;
  resellerGeraId: string;
}

const EditResellerPhoneForm = ({ resellerGeraId, resellerPhone }: EditResellerPhoneFormProps) => {
  const { resellerInfoEditDataSubmitted, resellerInfoEditDataErrorSubmitted } = useEventTracker();

  const methods = useForm({
    resolver: yupResolver(editResellerPhoneFormSchema),
    mode: 'onBlur',
    context: {
      existingPhone: resellerPhone,
      resellerGeraId,
    },
  });
  const {
    formState: { isSubmitSuccessful, isSubmitted },
  } = methods;

  const onSubmit = async (data: EditPhoneFormData) => {
    if (resellerGeraId === undefined) {
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerContact,
        errorMessage: 'Gera ID não pode ser nulo',
      });
      throw new Error('Gera ID não pode ser nulo');
    }

    try {
      await updateResellerPhone(resellerGeraId, { phone: cleanupSpecialCharacters(data.phone) });
      resellerInfoEditDataSubmitted({ editFormName: formName.resellerPhone });

      return 0;
    } catch (err) {
      const errorMessage = (await err.response.json()).response?.error;
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerPhone,
        errorMessage: err.message,
      });
      if (errorMessage === 'UPDATE_IN_PROGRESS') {
        methods.setError('root', { type: 'UPDATE_IN_PROGRESS' });
      } else {
        methods.setError('root', { type: 'REQUEST_ERROR' });
      }
    }
  };

  return (
    <Flex direction="column" gapY="$2" data-testid="edit-reseller-phone-form" css={{ marginTop: '$4', flex: 1 }}>
      <FormProvider {...methods}>
        <Box as="form" id="reseller-edit-phone-form" onSubmit={methods.handleSubmit(onSubmit)} css={{ flex: 1 }}>
          {isSubmitted ? (
            isSubmitSuccessful ? (
              <EditPhoneSuccessMessage />
            ) : (
              <EditPhoneErrorMessage />
            )
          ) : (
            <FormWrapper css={{ background: '$light-dark-1', padding: 0, paddingTop: '$2', flex: 1 }}>
              <Box css={{ gridColumn: 'span 12' }}>
                <FormInput
                  registerName="phone"
                  data-testid="input-phone"
                  id="phone"
                  labelText="Novo celular:"
                  placeholder="(__) ____-____"
                  mask="(xx) xxxxx-xxxx"
                  validText="Celular preenchido corretamente."
                />
              </Box>
              <Box css={{ gridColumn: 'span 12' }}>
                <FormInput
                  registerName="confirmPhone"
                  data-testid="input-confirm-phone"
                  id="confirm-phone"
                  labelText="Confirmar celular:"
                  placeholder="(__) ____-____"
                  mask="(xx) xxxxx-xxxx"
                  validText="Celular preenchido corretamente."
                />
              </Box>
            </FormWrapper>
          )}
        </Box>
        <Divider />
        <EditResellerPhoneFooter />
      </FormProvider>
    </Flex>
  );
};

export { EditResellerPhoneForm };
