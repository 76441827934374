import { TextInfo } from '@/components/shared';
import { EditContactButton } from '../EditContactButton';
import { events, useEventTracker } from '@/api';
import { useGetResellerLastUpdateStatus } from '@/api';
import { getPhoneUpdateStatusParameters } from './get-update-status-info';
import { Alert } from '@/components/shared/alert';
import { AttendanceInfoData } from '../../../shared/types';
import { ContactFooterText } from '../components/ContactFooterText';
import { EditResellerPhone } from '../../../edit-reseller-phone';
import { formatPhoneNumber } from '@/utils';

type ResellerContactPhoneProps = { phone: string; attendanceInfo: AttendanceInfoData };

export const ResellerContactPhone = ({ phone, attendanceInfo }: ResellerContactPhoneProps) => {
  const { clickedResellerInfoButtons } = useEventTracker();
  const { data: lastUpdateStatus, isLoading: lastUpdateStatusLoading } = useGetResellerLastUpdateStatus(
    attendanceInfo.geraId,
    { field: 'phone' },
  );

  const handleEditResellerPhone = () => {
    clickedResellerInfoButtons({
      section: 'alterar-dados-celular',
    });

    events.emit('OPEN_DRAWER_INFO', {
      size: 'medium',
      title: 'Alteração de celular',
      content: <EditResellerPhone attendanceInfo={attendanceInfo} resellerPhone={phone} />,
    });
  };

  const statusParameters = getPhoneUpdateStatusParameters(lastUpdateStatus);

  const shouldDisableEditButton = lastUpdateStatusLoading || statusParameters?.disabled;
  const disabledButtonTooltip = statusParameters?.buttonTooltip;

  const EditPhoneButton = (
    <EditContactButton
      label={`Editar celular`}
      onEdit={handleEditResellerPhone}
      disabled={shouldDisableEditButton}
      disabledTooltipText={disabledButtonTooltip}
    />
  );

  return (
    <>
      <TextInfo
        canCopyToClipboard
        isFullWidth
        title="Celular"
        text={formatPhoneNumber(phone)}
        actions={[EditPhoneButton]}
        footerElement={<ContactFooterText text={statusParameters?.infoText} type={statusParameters?.infoType} />}
      />
      {statusParameters?.alertText && (
        <Alert
          title={statusParameters.alertTitle}
          description={statusParameters.alertText}
          status={statusParameters.alertType}
        />
      )}
    </>
  );
};
