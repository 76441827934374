import { events } from '@/api';
import { LoadButton } from '@/components/shared';
import { Button, DrawerFooter } from '@grupoboticario/flora-react';
import { useFormContext } from 'react-hook-form';

export const EditResellerEmailFooter = () => {
  const {
    clearErrors,
    reset,
    formState: { errors, isValid, isSubmitting, isValidating, isSubmitSuccessful, isSubmitted },
  } = useFormContext();

  const updateInProgress = !isSubmitSuccessful && errors?.root?.type === 'UPDATE_IN_PROGRESS';

  const handleTryAgain = () => {
    clearErrors();
    reset();
  };

  const handleCloserDrawer = () => {
    events.emit('CLOSE_DRAWER_INFO');
    handleTryAgain();
  };

  return (
    <DrawerFooter css={{ flexDirection: 'row', justifyContent: 'space-between', padding: 0 }}>
      {!isSubmitSuccessful && isSubmitted && (
        <>
          {!updateInProgress && (
            <>
              <Button isFull hierarchy={'secondary'} onClick={handleCloserDrawer}>
                Ir para dados do revendedor
              </Button>
              <Button isFull onClick={handleTryAgain}>
                Tentar novamente
              </Button>
            </>
          )}
          {updateInProgress && (
            <Button isFull onClick={handleTryAgain}>
              Tentar novamente
            </Button>
          )}
        </>
      )}
      {isSubmitSuccessful && (
        <>
          <Button isFull onClick={handleCloserDrawer}>
            Ir para dados do revendedor
          </Button>
        </>
      )}
      {!isSubmitted && (
        <LoadButton
          isLoading={isSubmitting || isValidating}
          disabled={!isValid || isValidating}
          type="submit"
          form="reseller-form"
          label={`Salvar alteração`}
          isFull
        />
      )}
    </DrawerFooter>
  );
};
