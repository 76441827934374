import { Flex, Text } from '@grupoboticario/flora-react';
import { ReactElement } from 'react';
import {
  ExclamationMarkTriangleOutlineIcon,
  CheckCircleIcon,
  InformationCircleIcon,
  CrossCircleIcon,
} from '@grupoboticario/flora-react-icons';
interface ContactFooterTextProps {
  text: string;
  type: `info` | `success` | `error` | `warning`;
}

const getIcon = (type: `info` | `success` | `error` | `warning`): ReactElement => {
  switch (type) {
    case `info`:
      return <InformationCircleIcon size="16" color="$statusInfoActionableDefault" />;
    case `success`:
      return <CheckCircleIcon size="16" color="$statusSuccessActionableDefault" />;
    case `error`:
      return <CrossCircleIcon size="16" color="$statusErrorActionableDefault" />;
    case `warning`:
      return <ExclamationMarkTriangleOutlineIcon size="16" color="$statusAlertActionableDefault" />;
  }
};

export const ContactFooterText = ({ text, type }: ContactFooterTextProps) => {
  return (
    <Flex gap="$1" align="center">
      {getIcon(type)}
      <Text size="exceptionsAuxiliarRegular" color="$nonInteractiveAuxiliar">
        {text}
      </Text>
    </Flex>
  );
};
