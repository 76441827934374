import { useAccountInfo } from '@/api';
import { FeatureFlag } from '@/types';
import { useFeatureFlag } from 'configcat-react';

function useVDIFeatureFlag(
  featureFlag: FeatureFlag,
  expectedValue = true,
): { loading: boolean; isFeatureEnabled: boolean } {
  const { user } = useAccountInfo();
  const { value, loading } = useFeatureFlag(featureFlag, false, {
    identifier: user.geraId,
    custom: {},
  });

  return {
    loading,
    isFeatureEnabled: value === expectedValue,
  };
}

export { useVDIFeatureFlag };
