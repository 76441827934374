import { Flex, Text } from '@grupoboticario/flora-react';
import { Container } from './alert.styled';
import { CrossHexagonFillIcon, ExclamationMarkTriangleFillIcon } from '@grupoboticario/flora-react-icons';

interface AlertProps {
  description: string;
  title?: string;
  status?: 'success' | 'error' | 'warning' | 'info';
}

export const Alert = ({ description, title, status }: AlertProps) => {
  const renderIcon = () => {
    switch (status) {
      case 'error':
        return <CrossHexagonFillIcon size={20} />;
      case 'warning':
        return <ExclamationMarkTriangleFillIcon size={20} />;
    }
  };

  return (
    <Container status={status}>
      <Flex direction={'column'} gap="$2">
        <Flex gap="$2">
          {renderIcon()}
          <Text size={'bodyLargeStandardMedium'} weight="medium">
            {title || description}
          </Text>
        </Flex>
        {title && (
          <Text size={'bodySmallStandardRegular'} weight="medium">
            {description}
          </Text>
        )}
      </Flex>
    </Container>
  );
};
