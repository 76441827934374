import { addAttendanceToList } from '@/api';
import { ResellerVO, Service, ServiceStatus, myAttendancesKey } from '@/types';
import { mutate } from 'swr';

// @TODO Refatorar essa funcao para receber service
export async function addNewOpenService(
  reseller: ResellerVO,
  isUnifiedFlow: boolean,
): Promise<{
  addedService: ResellerVO | undefined;
  updatedServiceList: ResellerVO[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  error?: any;
}> {
  try {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const updatedServiceList: any = await mutate(
      myAttendancesKey,
      async (cachedAttendances: ResellerVO[] | undefined) => {
        const attendances = cachedAttendances ?? [];

        const isAlreadyIncluded = attendances.some(
          entry => (entry as unknown as Service).reseller.geraId === reseller.geraId,
        );

        if (!isAlreadyIncluded) {
          const createdAttendance = isUnifiedFlow
            ? await addAttendanceToList(reseller.geraId, ServiceStatus.IN_ATTENDANCE)
            : await addAttendanceToList(reseller.geraId);

          const attendanceToAdd = {
            reseller: {
              ...reseller,
              geraId: createdAttendance.reseller,
            },
            _id: createdAttendance._id,
          };

          return [attendanceToAdd, ...attendances];
        }

        return attendances;
      },
      false,
    );

    return {
      addedService: updatedServiceList.find(
        (item: Service) => (item as unknown as Service).reseller.geraId === reseller.geraId,
      ),
      updatedServiceList,
    };
  } catch (err) {
    return {
      addedService: undefined,
      updatedServiceList: [],
      error: err,
    };
  }
}
