import { Detail, DetailProps, Divider, InfoWrapper } from '@/components';

import { CSS, Text } from '@grupoboticario/flora-react';
import { ReactElement } from 'react';

interface TextInfoProps extends Omit<DetailProps, 'textSize' | 'variant'> {
  headerText?: string;
  isFullWidth?: boolean;
  wrapperCss?: CSS;
  actions?: ReactElement[];
  footerElement?: ReactElement;
}

const TextInfo = ({ headerText, isFullWidth, wrapperCss, ...rest }: TextInfoProps): JSX.Element => (
  <InfoWrapper isFullWidth={isFullWidth} css={wrapperCss}>
    {headerText && (
      <>
        <Text css={{ fontWeight: '$medium' }} size="bodyMediumStandard" color="$nonInteractiveAuxiliar">
          {headerText}
        </Text>
        <Divider />
      </>
    )}
    <Detail {...rest} />
  </InfoWrapper>
);

export { TextInfo };
