import { useEventTracker } from '@/api';
import { Box, Button, Flex, Text, Toaster, useClipboard } from '@grupoboticario/flora-react';
import { TwoPiledSquaresIcon } from '@grupoboticario/flora-react-icons';

const RE_MESSAGE = `A alteração do celular está em análise. \n
\t1. A confirmação pode levar até 2 dias úteis. \n
\t2. Você poderá acompanhar atualizações no aplicativo ou portal, acessando o “Perfil”. \n 
\t3. Você poderá receber notificações e solicitações de segurança no seu e-mail. \n
Em caso de dúvidas, estamos à disposição! 😊
`;

export const EditPhoneSuccessMessage = () => {
  const clipboard = useClipboard();
  const { infoCopyButtonClicked } = useEventTracker();

  const handleCopyMessage = () => {
    clipboard.copy(RE_MESSAGE);
    infoCopyButtonClicked({
      section: 'Mensagem sucesso editar celular',
    });

    Toaster.notify({
      kind: 'success',
      duration: 3000,
      button: 'Fechar',
      closeWhenClick: true,
      description: `Mensagem copiada`,
      origin: 'right-top',
    });
  };

  return (
    <Flex direction="column" gap="$6">
      <Text css={{ color: '$nonInteractivePredominant' }} size="subTitleMobileMedium" weight="medium">
        Solicitação de alteração realizada!
      </Text>
      <Text css={{ color: '$nonInteractivePredominant' }} size={'bodyLargeStandardRegular'}>
        Copie a mensagem sugerida e envie para o revendedor, orientando as próximas etapas.
      </Text>
      <Box css={{ backgroundColor: '$backgroundSecondary', padding: '$4', paddingRight: '$6', whiteSpace: 'pre-wrap' }}>
        <Flex direction="column" gap="$2">
          <Text size="bodyLargeShortRegular">{RE_MESSAGE}</Text>
        </Flex>
      </Box>
      <Flex justify="end">
        <Button
          has="iconLeft"
          icon={<TwoPiledSquaresIcon />}
          hierarchy="tertiary"
          size="small"
          onClick={handleCopyMessage}
        >
          Copiar mensagem
        </Button>
      </Flex>
    </Flex>
  );
};
