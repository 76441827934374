import { ResellerLastUpdateStatus, UpdateStatus } from '@/types';

interface StatusParameters {
  disabled: boolean;
  buttonTooltip: string;
  infoText: string;
  infoType: 'success' | 'error' | 'warning' | 'info';
  alertTitle: string;
  alertText: string;
  alertType: 'success' | 'error' | 'warning' | 'info';
  hideInDays?: number;
}

interface Templates {
  updateCompleted: StatusParameters;
  updateRecentlyCompleted: StatusParameters;
  updateInProgress: StatusParameters;
  updateInProgressWaitingTwoFa: StatusParameters;
  updateInProgressWaitingBio: StatusParameters;
  bioFraud: StatusParameters;
  twoFaFraud: StatusParameters;
  bioLinkExpired: StatusParameters;
  bioNotSent: StatusParameters;
  serverError: StatusParameters;
  updateFailed: StatusParameters;
}

const templates: Templates = {
  updateCompleted: {
    disabled: false,
    buttonTooltip: ``,
    infoText: `Alterado em dd/mm/aaaa`,
    infoType: `success`,
    alertTitle: ``,
    alertText: ``,
    alertType: `info`,
    hideInDays: 2,
  },
  updateRecentlyCompleted: {
    disabled: true,
    buttonTooltip:
      'Não é possível editar. Alteração realizada recentemente. Por segurança, só será possível realizar uma nova solicitação a partir de dd/mm, às xxh.',
    infoText: `Alterado em dd/mm/aaaa. A atualização na VD Interativa, no aplicativo e no portal será realizada em até 1h.`,
    infoType: `success`,
    alertTitle: ``,
    alertText: ``,
    alertType: `info`,
  },
  updateInProgress: {
    disabled: true,
    buttonTooltip: `Não é possível editar. Alteração em andamento.`,
    infoText: `Alteração em análise. O revendedor poderá acompanhar atualizações no aplicativo ou portal, acessando o “Perfil”.`,
    infoType: `warning`,
    alertTitle: `Não foi possível alterar o e-mail.`,
    alertText: ``,
    alertType: `info`,
  },
  updateInProgressWaitingTwoFa: {
    disabled: true,
    buttonTooltip: `Não é possível editar. Alteração em andamento.`,
    infoText: `Aguardando confirmação de segurança.`,
    infoType: `warning`,
    alertTitle: ``,
    alertText: `Solicite que o revendedor complete a etapa de segurança, respondendo a mensagem enviada por WhatsApp.`,
    alertType: `warning`,
  },
  updateInProgressWaitingBio: {
    disabled: true,
    buttonTooltip: `Não é possível editar. Alteração em andamento.`,
    infoText: `Aguardando etapa de segurança (biometria).`,
    infoType: `warning`,
    alertTitle: ``,
    alertText: `Solicite que o revendedor complete a etapa de segurança, através do link enviado para o WhatsApp ** ***** ZZZZ.`,
    alertType: `warning`,
  },
  bioFraud: {
    disabled: true,
    buttonTooltip: `Não é possível editar. A alteração precisa ser realizada em contato com a Central de Atendimento ao Revendedor (CAR).`,
    infoText: `Alteração não permitida.`,
    infoType: `error`,
    alertTitle: `Não foi possível alterar o e-mail.`,
    alertText: `A alteração foi negada em respeito às políticas internas. Solicite que o revendedor entre em contato com a Central de Atendimento ao Revendedor (CAR). `,
    alertType: `error`,
    hideInDays: 7,
  },
  twoFaFraud: {
    disabled: true,
    buttonTooltip: `Não é possível editar. A alteração precisa ser realizada em contato com a Central de Atendimento ao Revendedor (CAR).`,
    infoText: `Alteração não permitida.`,
    infoType: `error`,
    alertTitle: `Não foi possível alterar o e-mail.`,
    alertText: `A alteração foi negada em respeito às políticas internas. Solicite que o revendedor entre em contato com a Central de Atendimento ao Revendedor (CAR). `,
    alertType: `error`,
    hideInDays: 7,
  },
  bioLinkExpired: {
    disabled: false,
    buttonTooltip: `Não é possível editar. A alteração precisa ser realizada em contato com a Central de Atendimento ao Revendedor (CAR).`,
    infoText: `Alteração não realizada.`,
    infoType: `error`,
    alertTitle: `Não foi possível alterar o e-mail.`,
    alertText: `A etapa de segurança enviada para o WhatsApp ** ***** ZZZZ expirou. Por favor, inicie a alteração novamente.`,
    alertType: `error`,
    hideInDays: 3,
  },
  bioNotSent: {
    disabled: true,
    buttonTooltip: `Não é possível editar. A alteração precisa ser realizada em contato com a Central de Atendimento ao Revendedor (CAR).`,
    infoText: `Alteração não realizada.`,
    infoType: `error`,
    alertTitle: `Não foi possível alterar o e-mail.`,
    alertText: `Solicite que o revendedor entre em contato com a Central de Atendimento ao Revendedor (CAR). `,
    alertType: `error`,
    hideInDays: 3,
  },
  serverError: {
    disabled: false,
    buttonTooltip: ``,
    infoText: `Alteração não realizada.`,
    infoType: `error`,
    alertTitle: `Não foi possível alterar o e-mail.`,
    alertText: `Por favor, tente novamente. Se o erro persistir, solicite que o revendedor entre em contato com a Central de Atendimento ao Revendedor (CAR).`,
    alertType: `error`,
    hideInDays: 3,
  },
  updateFailed: {
    disabled: false,
    buttonTooltip: ``,
    infoText: `Alteração não realizada.`,
    infoType: `error`,
    alertTitle: `Não foi possível alterar o e-mail.`,
    alertText: `Por favor, tente novamente. Se o erro persistir, solicite que o revendedor entre em contato com a Central de Atendimento ao Revendedor (CAR).`,
    alertType: `error`,
    hideInDays: 3,
  },
};

export const getEmailUpdateStatusParameters = (
  lastUpdate: ResellerLastUpdateStatus,
  lastFourDigitsPhone: string = '****',
) => {
  if (!lastUpdate) return undefined;

  const statusParameters = templates[lastUpdate.status];

  if (statusParameters.hideInDays) {
    const limitDateToShow = new Date(lastUpdate.statusDate);
    limitDateToShow.setDate(limitDateToShow.getDate() + statusParameters.hideInDays);

    if (limitDateToShow < new Date()) {
      return undefined;
    }
  }

  const lastUpdateDate = new Date(lastUpdate.statusDate);
  const lastUpdateDatePlusTwoDays = new Date(lastUpdateDate);
  lastUpdateDatePlusTwoDays.setDate(lastUpdateDatePlusTwoDays.getDate() + 2);

  switch (lastUpdate.status) {
    case UpdateStatus.UPDATE_COMPLETED:
      return {
        ...statusParameters,
        infoText: statusParameters.infoText.replace('dd/mm/aaaa', lastUpdateDate.toLocaleDateString()),
      };
    case UpdateStatus.UPDATE_RECENTLY_COMPLETED:
      return {
        ...statusParameters,
        infoText: statusParameters.infoText.replace('dd/mm/aaaa', lastUpdateDate.toLocaleDateString()),
        buttonTooltip: statusParameters.buttonTooltip.replace(
          'dd/mm, às xxh',
          `${lastUpdateDatePlusTwoDays.toLocaleDateString()}, às ${new Date(lastUpdateDatePlusTwoDays.getTime() + 60 * 60000).toLocaleTimeString()}`,
        ),
      };
    case UpdateStatus.BIO_FA_WAITING_FOR_USER:
      return {
        ...statusParameters,
        alertText: statusParameters.alertText.replace('ZZZZ', lastFourDigitsPhone),
      };
    case UpdateStatus.BIO_LIFECYCLE_EXPIRED:
      return {
        ...statusParameters,
        alertText: statusParameters.alertText.replace('ZZZZ', lastFourDigitsPhone),
      };
    default:
      return templates[lastUpdate.status];
  }
};
