import React from 'react';
import { Flex, Grid, Text, CSS, FlexProps, Tooltip, Box, Button } from '@grupoboticario/flora-react';
import { ExclamationMarkChatBubbleIcon } from '@grupoboticario/flora-react-icons';
import { DataWrapperButton } from '@/components';

interface DataWrapperProps extends FlexProps {
  children: React.ReactNode;
  title?: string;
  css?: CSS;
  wrapperButtons?: DataWrapperButton[];
}

const DataWrapper = ({ title, children, css, wrapperButtons, ...rest }: DataWrapperProps): JSX.Element | null => {
  const hasChildren = React.Children.toArray(children).filter(Boolean);

  return hasChildren.length > 0 ? (
    <Flex direction="column" css={{ paddingTop: '$3', ...css }} {...rest}>
      <Flex align="end" justify="space-between">
        {title?.length && (
          <Text css={{ marginLeft: '$4', marginBottom: '$2' }} size="exceptionsAuxiliarRegular">
            {title}
          </Text>
        )}
        <Flex css={{ marginLeft: '$2' }}>
          {!!wrapperButtons?.length &&
            wrapperButtons.map(({ label, disabled, icon, tooltipText, action }) => {
              const MappedButton = (
                <Box key={label}>
                  <Button
                    data-testid="mapped-button"
                    onClick={action}
                    disabled={disabled}
                    css={{ margin: '0 0 $2 $2' }}
                    icon={icon ?? undefined}
                    has="iconLeft"
                  >
                    {label}
                  </Button>
                </Box>
              );
              return tooltipText ? (
                <Tooltip
                  key={label}
                  data-testid="data-wrapper-tooltip"
                  align="center"
                  icon={<ExclamationMarkChatBubbleIcon size="16" />}
                  text={tooltipText}
                >
                  {MappedButton}
                </Tooltip>
              ) : (
                MappedButton
              );
            })}
        </Flex>
      </Flex>
      <Grid gap="0.125rem" templateColumns="repeat(2, 1fr)">
        {children}
      </Grid>
    </Flex>
  ) : null;
};

export { DataWrapper };
