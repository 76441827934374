import { useRequest } from '@/api/hooks';
import { ResellerLastUpdateStatus } from '@/types';

export const useGetResellerLastUpdateStatus = (geraId: string, options?: { field?: 'email' | 'phone' }) => {
  const { data, error, isLoading } = useRequest<ResellerLastUpdateStatus[]>(
    geraId ? ['resellers/last-update-status', geraId] : null,
    `resellers/${geraId}/last-update-status`,
  );

  const requestedStatus = data?.find(status => status.fieldsChanged.includes(options?.field));

  return {
    data: requestedStatus,
    error,
    isLoading,
  };
};
