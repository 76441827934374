import { CheckCommercialStructureData } from '@/api';
import {
  ResellerCredit,
  ResellerDocumentStatus,
  ResellerDocumentEnum,
  ResellerDocumentStatusEnum,
  ResellerDocuments,
  Address,
  ResellerAddressType,
  ResellerRegistry,
  ResellerPhoneType,
  ResellerEmailType,
  ServiceStatus,
  UpdateStatus,
  ResellerLastUpdateStatus,
} from '@/types';

export const resellerCredit: ResellerCredit = {
  availableCreditLimit: 28355.3,
  totalCreditLimit: 30000,
  creditLimitInvoiced: 1644.7,
  creditTolerance: 0,
  creditLimitDue: 0,
};

const resellerDocumentStatus: ResellerDocumentStatus[] = [
  {
    tipoDocumento: ResellerDocumentEnum.RG,
    status: ResellerDocumentStatusEnum.PENDING,
  },
];

export const resellerDocuments: ResellerDocuments = {
  documentos: resellerDocumentStatus,
};

export const resellerAddresses: Address[] = [
  {
    type: ResellerAddressType.HOME,
    zipCode: '99999888',
    street: 'Rua dos Bobos',
    number: '0',
    complement: 'Casa A',
    state: 'PR',
    city: 'Curitiba',
    landmark: 'Capão da Imbuia',
  },
  {
    type: ResellerAddressType.DELIVERY,
    zipCode: '99999887',
    street: 'Rua da Liberdade',
    number: '10',
    complement: 'Casa B',
    state: 'PR',
    city: 'Curitiba',
    landmark: 'Capão da Imbuia',
  },
];

export const resellerRegistry: ResellerRegistry = {
  geraId: '764600',
  registrationOrigin: 4,
  socialName: 'Freddy Krueger',
  name: 'Freddy Krueger',
  cpf: '98956996385',
  rg: '149124818',
  birthday: '2000-01-01T00:00:00Z',
  commercialStructure: {
    code: '1588',
    name: '13768 - PR - EQUIPE IRIS',
    parentStructure: {
      code: '312',
      name: 'Loja 13768 - INTERBELLE COMERCIO DE PRODUTOS DE BELEZA LTDA ',
    },
  },
  phones: [
    {
      type: ResellerPhoneType.HOME,
      phone: '11986531111',
      smsAccepted: true,
    },
  ],
  emails: [{ type: ResellerEmailType.PERSONAL, email: '32434GSA@PRIMECONTROL.COM.BR' }],
  registrationDate: '2019-11-19T00:00:00Z',
  addresses: resellerAddresses,
  hasFirstOrder: false,
};

export const commercialStructureMockData: CheckCommercialStructureData = {
  canChange: true,
};

export const notChangeCommercialStructureMockData: CheckCommercialStructureData = {
  canChange: false,
};

export const startAttendanceMockData = {
  createdAt: new Date(),
  type: ServiceStatus.OPEN,
};

export const lastUpdateStatusCompletedMockData: ResellerLastUpdateStatus = {
  resellerId: '88079',
  statusDate: '2025-01-16T17:17:19.696Z',
  fieldsChanged: 'email',
  status: UpdateStatus.UPDATE_COMPLETED,
};
