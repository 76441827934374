import { events, useEventTracker } from '@/api';
import { updateResellerContact } from '@/api/reseller/service/updateResellerContact';
import { FormInput } from '@/components/form';
import { useEditReseller } from '@/components/reseller-details/hooks';
import { FormWrapper } from '@/components/shared';
import { ResellerPhoneType, ResellerEmailType, formName } from '@/types';
import { formatPhoneNumber, cleanupSpecialCharacters } from '@/utils';
import { Box, Flex, Text } from '@grupoboticario/flora-react';
import { FormProvider } from 'react-hook-form';
import { EditContactData, ContactFormData } from '../../types';
import { editResellerContactFormSchema } from './validators';
import { getPhoneNumberByType, getResellerEmail } from '@/components/reseller-details/utils';

interface EditResellerContactFormProps {
  initialData: EditContactData | undefined;
  resellerGeraId?: string;
}

const formatterContactData = (data: ContactFormData, isEmailValueChanged: boolean) => ({
  phones: [
    {
      type: ResellerPhoneType.MOBILE,
      phone: data.mobilePhoneNumber.number,
      smsAccepted: data.mobilePhoneNumber.smsAccepted,
    },
    {
      type: ResellerPhoneType.HOME,
      phone: data.homePhoneNumber.number,
      smsAccepted: data.homePhoneNumber.smsAccepted,
    },
  ],
  emails: isEmailValueChanged
    ? [
        {
          type: ResellerEmailType.PERSONAL,
          email: data.email,
        },
      ]
    : [],
});

const generateInitialFormattedValue = (
  initialData: EditContactData | undefined,
  resellerGeraId: string | undefined,
) => {
  const mobilePhoneNumber = getPhoneNumberByType(ResellerPhoneType.MOBILE, initialData?.phones);

  const homePhoneNumber = getPhoneNumberByType(ResellerPhoneType.HOME, initialData?.phones);
  const emailInitialData = getResellerEmail(initialData?.emails)?.toLocaleLowerCase();

  return {
    mobilePhoneNumber: {
      number: formatPhoneNumber(mobilePhoneNumber?.phone) ?? '',
      smsAccepted: mobilePhoneNumber?.smsAccepted ?? false,
    },
    homePhoneNumber: {
      number: formatPhoneNumber(homePhoneNumber?.phone) ?? '',
      smsAccepted: homePhoneNumber?.smsAccepted ?? false,
    },
    email: emailInitialData,
    resellerGeraId,
  };
};

const EditResellerContactForm = ({ initialData, resellerGeraId }: EditResellerContactFormProps) => {
  const initialFormattedValue = generateInitialFormattedValue(initialData, resellerGeraId);

  const { resellerInfoEditDataSubmitted, resellerInfoEditDataErrorSubmitted } = useEventTracker();

  const { methods, handleSubmit, getValues, hasChangesOnForm, setHasFormError, submittedResellerFormMessage } =
    useEditReseller({
      schema: editResellerContactFormSchema,
      initialData: initialFormattedValue,
      geraId: resellerGeraId,
      dataFormatter: (data: ContactFormData) => {
        return {
          ...data,
          mobilePhoneNumber: {
            ...data.mobilePhoneNumber,
            number: cleanupSpecialCharacters(data.mobilePhoneNumber.number),
          },
          homePhoneNumber: {
            ...data.homePhoneNumber,
            number: cleanupSpecialCharacters(data.homePhoneNumber.number),
          },
        };
      },
    });

  const onSubmit = async (data: ContactFormData) => {
    if (resellerGeraId === undefined) {
      setHasFormError(true);
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerContact,
        errorMessage: 'Gera ID não pode ser nulo',
      });
      throw new Error('Gera ID não pode ser nulo');
    }

    if (!hasChangesOnForm) {
      events.emit('CLOSE_DRAWER_INFO');
      return null;
    }

    const isEmailChanged = getValues('email') !== initialFormattedValue.email;

    try {
      const formattedData = formatterContactData(data, isEmailChanged) as EditContactData;
      const response = await updateResellerContact(resellerGeraId, formattedData);
      resellerInfoEditDataSubmitted({ editFormName: formName.resellerContact });

      submittedResellerFormMessage();
      return response;
    } catch (err) {
      setHasFormError(true);
      resellerInfoEditDataErrorSubmitted({
        editFormName: formName.resellerContact,
        errorMessage: err.message,
      });
      throw err;
    }
  };

  return (
    <Flex direction="column" gapY="$2" data-testid="edit-reseller-address-form">
      <Text as="h3" size="bodyMediumShort" css={{ paddingLeft: '$4' }}>
        Contato
      </Text>

      <FormProvider {...methods}>
        <Box as="form" id="reseller-form" onSubmit={handleSubmit(onSubmit)}>
          <FormWrapper css={{ background: '$light-dark-1', padding: '$4' }}>
            <Box css={{ gridColumn: 'span 6' }}>
              <FormInput
                registerName="mobilePhoneNumber.number"
                data-testid="input-mobilePhoneNumber"
                id="mobilePhoneNumber"
                labelText="Celular:"
                placeholder="(__) ____-____"
                mask="(xx) xxxxx-xxxx"
                optionalText="*"
              />
            </Box>

            <Box css={{ gridColumn: 'span 6' }}>
              <FormInput
                registerName="homePhoneNumber.number"
                data-testid="input-homePhoneNumber"
                id="homePhoneNumber"
                labelText="Telefone Residencial:"
                placeholder="(__) ____-____"
                mask="(xx) xxxxx-xxxx"
                optionalText="*"
              />
            </Box>
            <Box css={{ gridColumn: 'span 12' }}>
              <FormInput
                registerName="email"
                data-testid="input-email"
                id="email"
                labelText="E-mail:"
                placeholder="email@email.com"
              />
            </Box>
          </FormWrapper>
        </Box>
      </FormProvider>
    </Flex>
  );
};

export { EditResellerContactForm };
