import { Flex } from '@grupoboticario/flora-react';
import { AttendanceInfoData } from '@/components/reseller-details/';
import { EditResellerPhoneForm } from './components';

interface EditResellerPhoneProps {
  attendanceInfo: AttendanceInfoData;
  resellerPhone: string;
}

const EditResellerPhone = ({ attendanceInfo, resellerPhone }: EditResellerPhoneProps) => (
  <Flex data-testid="edit-reseller-phone-wrapper" direction="column" gap="$6" css={{ height: '100%' }}>
    <EditResellerPhoneForm resellerGeraId={attendanceInfo.geraId} resellerPhone={resellerPhone} />
  </Flex>
);

export { EditResellerPhone };
