import { events, useEventTracker } from '@/api';
import {
  EditResellerContact,
  EditResellerDrawerFooter,
  useResellerDataEditEnabler,
} from '@/components/reseller-details';
import { getPhoneNumberByType, getResellerEmail } from '@/components/reseller-details/utils';
import { DataWrapper, TextInfo } from '@/components/shared';
import { useFeatureFlagContext, useResellerDetails } from '@/contexts';
import { ResellerPhoneType } from '@/types';
import { ResellerContactEmail } from './reseller-contact-email/ResellerContactEmail';
import { ResellerContactPhone } from './reseller-contact-phone/ResellerContactPhone';
import { cleanupSpecialCharacters, formatPhoneNumber } from '@/utils';
import { EditContactButton } from './EditContactButton';

// ver memo
const ResellerContact = (): JSX.Element => {
  const { data } = useResellerDetails().resellerRegistry;
  const mobilePhoneNumber = getPhoneNumberByType(ResellerPhoneType.MOBILE, data?.phones);

  const { viewMadmaxResellerUpdate } = useFeatureFlagContext();

  const { isEditingDisabled, disabledButtonTooltip } = useResellerDataEditEnabler();

  const { clickedResellerInfoButtons } = useEventTracker();

  const attendanceInfoData = {
    geraId: data?.geraId,
    socialName: data?.socialName,
    cpCs: data?.commercialStructure?.parentStructure.name,
  };

  const resellerInitialContactData = {
    phones: data?.phones ?? [],
    emails: data?.emails ?? [],
  };

  const openEditResellerPhoneAndEmail = () => {
    clickedResellerInfoButtons({
      section: 'alterar-dados-contato',
    });

    events.emit('OPEN_DRAWER_INFO', {
      size: 'medium',
      title: 'Altere os dados de contato:',
      content: (
        <EditResellerContact
          attendanceInfo={attendanceInfoData}
          resellerInitialContactData={resellerInitialContactData}
        />
      ),
      footer: <EditResellerDrawerFooter />,
    });
  };

  const handleEditContact = () => {
    openEditResellerPhoneAndEmail();
  };

  const email = getResellerEmail(data?.emails)?.toLocaleLowerCase();

  const editContactButton = (
    <EditContactButton
      label={`Editar contato`}
      onEdit={handleEditContact}
      disabled={isEditingDisabled}
      disabledTooltipText={disabledButtonTooltip}
    />
  );

  if (!viewMadmaxResellerUpdate.isFeatureEnabled) {
    return (
      <DataWrapper title="Contato" data-testid="reseller-contact">
        <TextInfo
          canCopyToClipboard
          isFullWidth
          textToCopy={cleanupSpecialCharacters(mobilePhoneNumber?.phone ?? '')}
          title="Celular"
          text={formatPhoneNumber(mobilePhoneNumber?.phone)}
          actions={[editContactButton]}
        />
        <TextInfo canCopyToClipboard isFullWidth title="E-Mail" text={email} actions={[editContactButton]} />
      </DataWrapper>
    );
  }

  return (
    <DataWrapper title="Contato" data-testid="reseller-contact">
      <ResellerContactPhone attendanceInfo={attendanceInfoData} phone={mobilePhoneNumber?.phone} />
      <ResellerContactEmail attendanceInfo={attendanceInfoData} email={email} phone={mobilePhoneNumber?.phone} />
    </DataWrapper>
  );
};

export { ResellerContact };
