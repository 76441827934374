import { Flex, Text } from '@grupoboticario/flora-react';
import { ExclamationMarkHexagonIcon } from '@grupoboticario/flora-react-icons';
import { useFormContext } from 'react-hook-form';

export const EditEmailErrorMessage = () => {
  const {
    formState: { errors },
  } = useFormContext();

  const updateInProgress = errors?.root?.type === 'UPDATE_IN_PROGRESS';

  return (
    <Flex
      direction={'column'}
      align={'center'}
      justify={'center'}
      gap="$4"
      css={{ flex: 1, height: '100%', textAlign: 'center', padding: '$8' }}
    >
      <ExclamationMarkHexagonIcon color="$nonInteractivePredominant" size={45} />
      <Text size={'subTitleDeskMedium'} css={{ color: '$nonInteractiveEmphasis' }} weight={'medium'}>
        Não foi possível alterar o e-mail.
      </Text>
      {updateInProgress ? (
        <Text size={'bodyLargeStandardMedium'} css={{ color: '$nonInteractivePredominant' }} weight={'medium'}>
          Uma solicitação de alteração de e-mail já está em andamento. Informe o revendedor para aguardar as
          atualizações no aplicativo ou portal, acessando o “Perfil”.
        </Text>
      ) : (
        <Text size={'bodyLargeStandardMedium'} css={{ color: '$nonInteractivePredominant' }} weight={'medium'}>
          Por favor, tente novamente ou retorne mais tarde.
        </Text>
      )}
    </Flex>
  );
};
