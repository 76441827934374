import { styled } from '@grupoboticario/flora';
import { Box } from '@grupoboticario/flora-react';

export const Container = styled(Box, {
  padding: '$4',
  border: '1px solid',
  borderRadius: '8px',
  gridColumn: '1 / span 2',
  variants: {
    status: {
      success: {
        backgroundColor: '$statusSuccessBackgroundDefault',
        color: '$statusSuccessNonInteractiveEmphasis',
        borderColor: '$statusSuccessNonInteractiveEmphasis',
      },
      error: {
        backgroundColor: '$statusErrorBackgroundDefault',
        color: '$statusErrorNonInteractiveEmphasis',
        borderColor: '$statusErrorNonInteractiveEmphasis',
      },
      warning: {
        backgroundColor: '$statusAlertBackgroundDefault',
        color: '$statusAlertNonInteractiveEmphasis',
        borderColor: '$statusAlertNonInteractiveEmphasis',
      },
      info: {
        backgroundColor: '$statusInfoBackgroundDefault',
        color: '$statusInfoNonInteractiveEmphasis',
        borderColor: '$statusInfoNonInteractiveEmphasis',
      },
    },
  },
});
