import * as yup from 'yup';
import { EditPhoneFormData } from '../../../types';
import { searchReseller } from '@/api';
import { SearchType } from '@/types';
import { HTTPError } from 'ky';

const MIN_PHONES_CHARACTERS = 10;

const INPUT_ERROR_MESSAGE = 'Esse campo é obrigatório.';
const PHONE_ERROR_MESSAGE = 'Celular inválido. Tente novamente.';
const CONFIRM_PHONE_NOT_EQUAL = 'Os celulares não estão iguais. Digite novamente.';
const SAME_PHONE_ERROR = 'O celular informado é o mesmo do cadastro.';
const PHONE_NOT_AVAILABLE_MESSAGE = 'O celular informado já está sendo utilizado.';

// ignorando TS por problema de validação do schema https://github.com/jquense/yup/issues/959
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export const editResellerPhoneFormSchema: yup.ObjectSchema<EditPhoneFormData> = yup.object({
  phone: yup
    .string()
    .required(INPUT_ERROR_MESSAGE)
    .min(MIN_PHONES_CHARACTERS, PHONE_ERROR_MESSAGE)
    .test({
      message: SAME_PHONE_ERROR,
      test(value: string | undefined) {
        if (!value) {
          return false;
        }

        return value !== this.options.context?.existingPhone;
      },
    })
    .test({
      message: PHONE_NOT_AVAILABLE_MESSAGE,
      async test(value: string | undefined) {
        if (!value) {
          return false;
        }
        const { resellerGeraId } = this.options.context;
        try {
          const foundResellerByPhone = (await searchReseller(value, SearchType.PHONE)).find(
            reseller => reseller.phone === value,
          );

          if (foundResellerByPhone?.geraId === resellerGeraId) {
            return true;
          }
        } catch (error) {
          if (error && (error as HTTPError).response?.status === 404) {
            return true;
          }
          return true;
        }
        return false;
      },
    }),
  confirmPhone: yup
    .string()
    .required(INPUT_ERROR_MESSAGE)
    .min(MIN_PHONES_CHARACTERS, PHONE_ERROR_MESSAGE)
    .test({
      message: CONFIRM_PHONE_NOT_EQUAL,
      test(value: string | undefined) {
        return value == this.resolve(yup.ref('phone'));
      },
    }),
});
