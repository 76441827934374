import { useEventTracker } from '@/api';
import { TextOrHeading, TextOrHeadingProps, capitalizeText } from '@/utils';
import { Box, Button, Flex, Text, Toaster, Tooltip, useClipboard } from '@grupoboticario/flora-react';
import { TwoPiledSquaresIcon, QuestionMarkCircleIcon } from '@grupoboticario/flora-react-icons';
import { ReactElement } from 'react';

export interface DetailProps extends Omit<TextOrHeadingProps, 'color' | 'headingLevel' | 'text'> {
  canCopyToClipboard?: boolean;
  textToCopy?: string;
  helpTooltip?: string;
  isCapitalized?: boolean;
  title?: string;
  text?: TextOrHeadingProps['text'];
  fallbackText?: string;
  actions?: ReactElement[];
  footerElement?: ReactElement;
}

const formatText = (text: string | string[] | undefined, fallbackText: string, isCapitalized = false) => {
  if (text === undefined || text === null || !text.length) {
    return fallbackText;
  }

  if (!isCapitalized) {
    return text;
  }

  return text instanceof Array ? text.map(textToFormat => capitalizeText(textToFormat)) : capitalizeText(text);
};

const Detail = ({
  canCopyToClipboard,
  textToCopy,
  helpTooltip,
  isCapitalized,
  text,
  actions,
  textSize = 'bodyLargeShortRegular',
  title,
  variant = 'text',
  fallbackText = '- - -',
  footerElement,
}: DetailProps): JSX.Element => {
  const clipboard = useClipboard();
  const { infoCopyButtonClicked } = useEventTracker();

  const onCopy = () => {
    clipboard.copy(textToCopy ?? (text as string));
    infoCopyButtonClicked({
      section: title,
    });

    Toaster.notify({
      kind: 'success',
      duration: 3000,
      button: 'Fechar',
      closeWhenClick: true,
      description: `${title} copiado`,
      origin: 'right-top',
    });
  };

  const allowCopyToClipboard = !!canCopyToClipboard && text !== '' && text !== undefined;
  const formattedTextToDisplay = formatText(text, fallbackText, isCapitalized);

  return (
    <Box data-testid="detail-box">
      <Flex align="center">
        <Flex direction="column" css={{ flex: 1 }}>
          <Flex align="center">
            <Text size="exceptionsRestrictedRegular" color="$nonInteractiveAuxiliar">
              {title}
            </Text>
            {helpTooltip && (
              <Flex
                css={{
                  marginLeft: '$1',
                  color: '$nonInteractiveAuxiliar',
                  button: { width: 'unset', height: 'unset' },
                }}
                align="center"
              >
                <Tooltip direction="bottom" text={helpTooltip} icon={<QuestionMarkCircleIcon />}>
                  <Button
                    hierarchy="tertiary"
                    has="iconOnly"
                    icon={
                      <QuestionMarkCircleIcon
                        css={{
                          color: '$nonInteractiveAuxiliar',
                          '& svg': {
                            width: '$3',
                          },
                        }}
                      />
                    }
                    css={{ padding: '$1' }}
                    aria-label="Visualizar texto de ajuda"
                  />
                </Tooltip>
              </Flex>
            )}
          </Flex>
          <TextOrHeading
            color="$nonInteractivePredominant"
            headingLevel={6}
            text={formattedTextToDisplay}
            textSize={textSize}
            variant={variant}
          />
          {footerElement}
        </Flex>
        <Flex>
          {allowCopyToClipboard && (
            <Button
              hierarchy="tertiary"
              has="iconOnly"
              css={{
                marginLeft: '$1',
                padding: '$1',
              }}
              icon={
                <TwoPiledSquaresIcon
                  css={{
                    color: '$brand-1',
                    '& svg': {
                      width: '$6',
                      height: '$6',
                    },
                  }}
                />
              }
              onClick={onCopy}
              aria-label="Copiar texto"
            />
          )}
          {actions}
        </Flex>
      </Flex>
    </Box>
  );
};

export { Detail };
