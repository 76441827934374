import { useResellerDataEditEnabler } from '@/components/reseller-details/hooks';
import { Tooltip, Box, Button } from '@grupoboticario/flora-react';
import { ExclamationMarkChatBubbleIcon, PencilIcon } from '@grupoboticario/flora-react-icons';

interface EditContactButton {
  label: string;
  onEdit: () => void;
  disabledTooltipText?: string;
  disabled?: boolean;
}

const EditContactButton = ({ onEdit, label, disabledTooltipText, disabled }: EditContactButton) => {
  const { isEditingDisabled, disabledButtonTooltip } = useResellerDataEditEnabler();

  const isDisabled = isEditingDisabled || disabled;

  const button = (
    <Button
      hierarchy="tertiary"
      has="iconOnly"
      css={{
        marginLeft: '$1',
        padding: '$1',
      }}
      icon={
        <PencilIcon
          css={{
            color: disabled ? '$disabledContent' : '$brand-1',
            '& svg': {
              width: '$6',
              height: '$6',
            },
          }}
        />
      }
      onClick={onEdit}
      aria-label={label}
      disabled={isEditingDisabled || disabled}
    />
  );

  if (!isDisabled) {
    return button;
  }

  return (
    <Tooltip
      align="center"
      icon={<ExclamationMarkChatBubbleIcon size="16" />}
      text={disabledButtonTooltip || disabledTooltipText}
    >
      <Box>{button}</Box>
    </Tooltip>
  );
};

export { EditContactButton };
